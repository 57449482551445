<template>
  <div class="d-flex flex-column pa-2 mt-4 full-width">
    <grid
      item-min-width="300px"
      class="align-center"
      v-if="hasErrors"
    >
      <v-text-field
        v-for="(error, idx) in errors"
        :key="idx"
        class="mx-2"
        :value="error"
        readonly
      />
    </grid>
    <grid
      item-min-width="300px"
      class="align-center "
      v-if="showDetail"
    >
      <v-text-field
        class="mx-2"
        :label="$t('t.NumberDisputeUpdated')"
        :value="disputeUpdated"
        readonly
      />
      <v-text-field
        class="mx-2"
        :label="$t('t.NumberWorkItemCreated')"
        :value="workItemCreated"
        readonly
      />
      <v-text-field
        class="mx-2"
        :label="$t('t.NumberWorkItemClosed')"
        :value="workItemClosed"
        readonly
      />
    </grid>
  </div>
</template>

<script>
// import { CacheType } from '@/wasm/pkg'

export default {
  name: 'dispute',
  created () {
    this.$store.commit('setModule', {
      name: this.$t('t.DisputeRun')
    })
  },
  components: {
    Grid: () => import('@/components/grid')
  },
  computed: {
    hasErrors () {
      return this.data?.errors.length
    },
    showDetail () {
      return this.data?.showDetail || !this.hasErrors
    },
    errors () {
      return this.data?.errors
    },
    disputeUpdated () {
      return this.data?.disputeUpdated
    },
    workItemCreated () {
      return this.data?.workItemCreated
    },
    workItemClosed () {
      return this.data?.workItemClosed
    }
  },
  data () {
    return {
      data: null
      // cacheType: CacheType.EscalationProtocolRef
    }
  },
  methods: {
  },
  async mounted () {
    const url = '/core/run/recap'
    const r = await this.$http().get(`${url}/${this.$route.params.id}`)
    this.data = r.data
  }

}
</script>
<style lang="stylus" scoped>
.full-width
  width 100%

.v-text-field
  min-width 250px
</style>
